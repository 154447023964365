import React from "react";
import data from "../data/error404";
import Layout from "../components/Layout";
import ComponentIterator from "../components/ComponentIterator";

const NotFoundPage = () => (
  <Layout menu={data.menu}>
    <ComponentIterator components={data.components} />
  </Layout>
);

export default NotFoundPage;
