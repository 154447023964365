import menu from "./sections/Menu";
import meta from "./sections/Meta";
import Footer from "./sections/Footer";

const data = {
  meta,
  menu: menu(),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "Error 404", hrefTo: "/404" },
    ],
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "blue-light",
      },
      components: [
        {
          name: "Error404",
          props: {
            titleImage1: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/error_404/404_1.png",
              alt: "",
            },
            titleImage2: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/error_404/404_2.png",
              alt: "",
            },
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/error_404/404_3.png",
              alt: "",
            },
            htmlText:
              "<p>Chill Bill has frozen this page.<br>Save yourself.</p>",
            cta: {
              title: "Take me home",
              hrefTo: "/",
            },
          },
        },
      ],
    },
    Footer,
  ],
};

export default data;
